@import "variables";

@font-face {
  font-family: '#{$guilt-font-family}';
  src: url('#{$guilt-font-path}/#{$guilt-font-family}.eot?j4dyj2');
  src: url('#{$guilt-font-path}/#{$guilt-font-family}.eot?j4dyj2#iefix') format('embedded-opentype'),
  url('#{$guilt-font-path}/#{$guilt-font-family}.ttf?j4dyj2') format('truetype'),
  url('#{$guilt-font-path}/#{$guilt-font-family}.woff?j4dyj2') format('woff'),
  url('#{$guilt-font-path}/#{$guilt-font-family}.svg?j4dyj2##{$guilt-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.gt {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$guilt-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.gt-warning-outline {
  &:before {
    content: $gt-warning-outline;
  }
}

.gt-warning {
  &:before {
    content: $gt-warning;
  }
}

.gt-add-suspect {
  &:before {
    content: $gt-add-suspect;
  }
}

.gt-suspect {
  &:before {
    content: $gt-suspect;
  }
}

.gt-search {
  &:before {
    content: $gt-search;
  }
}

.gt-qrcode {
  &:before {
    content: $gt-qrcode;
  }
}

.gt-checkmark-filled {
  &:before {
    content: $gt-checkmark-filled;
  }
}

.gt-checkmark {
  &:before {
    content: $gt-checkmark;
  }
}

.gt-bell {
  &:before {
    content: $gt-bell;
  }
}

.gt-times-filled {
  &:before {
    content: $gt-times-filled;
  }
}

.gt-times {
  &:before {
    content: $gt-times;
  }
}

.gt-edit {
  &:before {
    content: $gt-edit;
  }
}

.gt-minus {
  &:before {
    content: $gt-minus;
  }
}

.gt-list {
  &:before {
    content: $gt-list;
  }
}

.gt-info {
  &:before {
    content: $gt-info;
  }
}

.gt-help {
  &:before {
    content: $gt-help;
  }
}

.gt-grid {
  &:before {
    content: $gt-grid;
  }
}

.gt-image {
  &:before {
    content: $gt-image;
  }
}

.gt-filter {
  &:before {
    content: $gt-filter;
  }
}

.gt-error {
  &:before {
    content: $gt-error;
  }
}

.gt-dossier {
  &:before {
    content: $gt-dossier;
  }
}

.gt-trash {
  &:before {
    content: $gt-trash;
  }
}

.gt-checkmark-small {
  &:before {
    content: $gt-checkmark-small;
  }
}

.gt-catalog {
  &:before {
    content: $gt-catalog;
  }
}

.gt-times-small {
  &:before {
    content: $gt-times-small;
  }
}

.gt-add-archive {
  &:before {
    content: $gt-add-archive;
  }
}

.gt-archive {
  &:before {
    content: $gt-archive;
  }
}

.gt-plus {
  &:before {
    content: $gt-plus;
  }
}
