$primary-color: #00ca9d;
$secondary-color: #f18939;
$tertiary-color: #29A1DA;
$warning-color: #F5A623;
$danger-color: #E3475E;
$text-color:#5F5D70;
$light-green-bg: #d0e4d1;
$light-background: #EBEEF3;
$lighter-background: #F4F6F9;
$blue-btn-bg: #1B55A1;
$yellow-btn-bg: #F5A623;
$color-dark: #183029;

@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/montserrat/Montserrat-Light.ttf");
}

@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 300;
  src: url("../fonts/montserrat/Montserrat-LightItalic.ttf");
}

@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/montserrat/Montserrat-Regular.ttf");
}

@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 400;
  src: url("../fonts/montserrat/Montserrat-RegularItalic.ttf");
}

@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/montserrat/Montserrat-Medium.ttf");
}

@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 500;
  src: url("../fonts/montserrat/Montserrat-MediumItalic.ttf");
}

@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  src: url("../fonts/montserrat/Montserrat-SemiBold.ttf");
}

@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 600;
  src: url("../fonts/montserrat/Montserrat-SemiBoldItalic.ttf");
}

@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/montserrat/Montserrat-Bold.ttf");
}

@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 700;
  src: url("../fonts/montserrat/Montserrat-BoldItalic.ttf");
}

@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 900;
  src: url("../fonts/montserrat/Montserrat-Bold.ttf");
}

@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 900;
  src: url("../fonts/montserrat/Montserrat-BoldItalic.ttf");
}

//mixin 
@mixin aspect-ratio($width, $height) {
  position: relative;

  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }

  >.cover-image-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 1px solid #979797;
    background: #fff;
    border-radius: 4px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    i {
      font-size: 7rem;
      color: #DAD9E2;
    }

    >div {
      color: $tertiary-color;
      text-decoration: underline;
      font-size: 0.875rem;
      margin-top: 30px;
    }
  }
}

@mixin button($color) {
  background: $color !important;
  border: 1px solid $color !important;
  transition: all .3s ease-in-out;

  &.button-outlined {
    background: transparent !important;
    border: 1px solid $color !important;
    color: $color !important
  }

  &.text {
    background: transparent !important;
    color: $color !important;
    border: none !important;

    &:hover {
      color: $color !important;
      background: transparent !important;

      &:not(:disabled):before {
        visibility: visible;
        transform: scaleX(1);
      }
    }

    &:before {
      content: "";
      position: absolute;
      width: calc(100% - 2rem);
      height: 3px;
      bottom: 0;
      left: 1rem;
      background: $color;
      visibility: hidden;
      border-radius: 5px;
      transform: scaleX(0);
      transition: .25s linear;
    }
  }

  &:hover {
    box-shadow: none;
    background: darken($color, 10%) !important;
    color: #fff !important;
  }

  &:active,
  &:focus {
    box-shadow: none !important;
  }
}