$guilt-font-family: "guilt" !default;
$guilt-font-path: "fonts" !default;

$gt-warning-outline: "\e900";
$gt-warning: "\e901";
$gt-add-suspect: "\e902";
$gt-suspect: "\e903";
$gt-search: "\e904";
$gt-qrcode: "\e905";
$gt-checkmark-filled: "\e906";
$gt-checkmark: "\e907";
$gt-bell: "\e908";
$gt-times-filled: "\e909";
$gt-times: "\e90a";
$gt-edit: "\e90b";
$gt-minus: "\e90c";
$gt-list: "\e90d";
$gt-info: "\e90e";
$gt-help: "\e90f";
$gt-grid: "\e910";
$gt-image: "\e911";
$gt-filter: "\e912";
$gt-error: "\e913";
$gt-dossier: "\e914";
$gt-trash: "\e915";
$gt-checkmark-small: "\e916";
$gt-catalog: "\e917";
$gt-times-small: "\e918";
$gt-add-archive: "\e919";
$gt-archive: "\e91a";
$gt-plus: "\e91b";

