@import "variables";

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Montserrat', "Helvetica Neue", sans-serif;
}

* {
  outline: 0 !important;
  font-family: 'Montserrat',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    Oxygen,
    Ubuntu,
    Cantarell,
    'Open Sans',
    'Helvetica Neue',
    sans-serif;
}

.content-wrapper {
  display: flex;
  height: calc(100vh - 77px);
}

#inner-content-wrapper {
  position: relative;
  width: calc(100vw - 215px)
}

.login-wrapper {
  height: 100vh;

  #inner-content-wrapper {
    width: 100vw
  }
}

.p-component {
  font-family: 'Montserrat',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    Oxygen,
    Ubuntu,
    Cantarell,
    'Open Sans',
    'Helvetica Neue',
    sans-serif !important;
}

.loader {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100% - 98px);
  justify-content: center;
  align-items: center;
}

.title-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 30px;

  button {

    &.small-screen-btn {
      min-width: 40px;
      min-height: 40px;
    }
  }

  .p-inputgroup {
    border: 1px solid #ccc;
    border-radius: 20px;
    max-width: 600px;
    min-width: 350px;
    width: 50%;
    background: #fff;
    transition: all .3s ease;

    &:focus-within {
      border-color: $primary-color;
    }

    input {
      border: 0;
      padding-left: 20px;
      border-radius: 20px !important;

    }

    span {
      border: 0;
      background: transparent;
      border-radius: 20px;

    }
  }
}

.general-btn-wrapper {
  margin-bottom: 5px;
  display: flex;
  justify-content: flex-start;

  button {
    font-size: 0.875rem;
    text-decoration: underline;

    &:not(.delete-btn) {
      color: #5F5D70;
      text-decoration: none;

    }
  }
}

.element-detail-container {
    .p-scrollpanel p {
      padding: 0
    }
}

.story-desc {
  .p-scrollpanel-wrapper {
    border-right: 5px solid #fff;

    .p-scrollpanel-content {
      background: #fff !important;
      padding: 10px 25px !important;
    }
  }

  .p-scrollpanel-bar {
    background-color: $secondary-color !important;
    opacity: 1;
    transition: background-color .2s;

    &:hover {
      background-color: $secondary-color !important;
    }
  }

  .p-scrollpanel-bar-y {
    width: 5px;
  }
}

@media screen and (max-width:990px) {
  .title-bar {
    h2 {
      order: 0
    }

    button {
      order: 1
    }

    .p-inputgroup {
      order: 2;
      flex-basis: 100%;
      margin-bottom: 20px;
      max-width: 100%;
    }
  }
}
