@import 'variables';


/*PRIMENG*/
input {
  color: #333333;
  padding: 0 10px;

  &.p-inputtext:enabled:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none;
    border-width: 1px;
    border-color: $primary-color;
  }
}

.error-message {
  font-size: 0.875rem;
  color: $danger-color;
}

.p-divider:before {
  border-top-color: $text-color !important;
}

.p-scrollpanel {
  p {
    padding: .5rem;
    line-height: 1.5;
    margin: 0;
  }

  .p-scrollpanel-wrapper {
    border-right: 9px solid #f6f6f9;

    .p-scrollpanel-content {
      padding: 10px 40px;
      background: #F6F6F9;
    }
  }

  .p-scrollpanel-bar {
    background-color: $primary-color !important;
    opacity: 1;
    transition: background-color .2s;

    &:hover {
      background-color: $secondary-color !important;
    }
  }


  &.detail-scroll {
    flex-basis: 100%;

    .p-scrollpanel-wrapper {
      border-right: 9px solid transparent;
    }

    .p-scrollpanel-content {
      padding: 10px 0;
      background: transparent;
    }
  }
}

.detail-scroll-wrapper {
  flex-basis: 100%
}

.p-checkbox {
  .p-checkbox-box {
    &.p-highlight {
      background-color: $primary-color !important;
      border-color: $primary-color !important
    }
  }
}

.p-toolbar {
  background: #fff !important;
  color: #333333;
  border: 0 !important;
  border-bottom: 2px solid #E6E8F1 !important;
  border-radius: 0 !important;
  z-index: 999
}

.ui-dialog-titlebar {
  display: flex;
  align-items: center;
}

.ui-dialog-title {
  color: $primary-color;
  font-size: 1.3rem;
  flex: 1;
}

.ui-dialog-content {
  .ng-star-inserted {
    width: 100%;
  }
}

.ui-state-highlight {
  background-color: $primary-color !important;
}

.ui-dropdown {
  min-width: 150px !important;
}

.ui-inputtext:enabled:focus:not(.ui-state-error),
.ui-state-focus {
  border-color: $primary-color !important;
}

.pi-search:before {
  color: $primary-color;
}

p-dropdown {
  .p-dropdown {
    &:not(.p-disabled).p-focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: none;
      border-color: $primary-color;
    }
  }
}

.p-dropdown-panel {
  .p-dropdown-items {
    .p-dropdown-item.p-highlight {
      background: $primary-color !important;
    }
  }
}

.p-dialog {
  max-height: 100% !important;

  .p-dialog-header {
    background: $primary-color !important;
    border-bottom: 1px solid $primary-color !important;
    color: #fff !important;
    padding: 1rem 1.5rem !important;

    .p-dialog-header-icons {
      button {
        color: #ffffff !important;

        &:enabled:hover {
          background: #fff !important;
          color: $primary-color !important;
        }
      }
    }
  }

  .p-dialog-content {
    padding: 0 0 1rem !important;
  }
}


.warning-dialog {
  .p-dialog-header {
    background: $warning-color !important;
    border-bottom: 1px solid $warning-color !important;
    font-weight: 500;

    >div:not(.p-dialog-header-icons) {
      display: flex;
      align-items: center;

      i {
        margin-right: 10px;
        font-size: 1.2rem;
      }
    }

  }

  .p-dialog-content {
    padding: 1rem !important;
    font-weight: 500;
    font-size: 1.1rem;
  }
}

.p-confirm-dialog .p-dialog-content {
  padding: 1.5rem !important;

}

.p-password {
  width: 100%;
  height: 64px;
  box-shadow: 2px 4px 8px 2px rgb(236, 236, 236);
  margin: 5px 0;

  input {
    color: #333333;
    height: 64px;
    width: 100%;
    border-width: 0;
    padding: 0 10px;

    &.p-inputtext:enabled:focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: 0 0 0 0.2rem rgba(0, 202, 157, .5);
      border-width: 1px;
      border-color: $primary-color;
    }
  }

  i {
    font-size: 1.3rem;
  }
}

p-splitButton {

  .p-splitbutton-defaultbutton,
  .p-splitbutton-menubutton {
    background: $secondary-color;
    border: 0;
    padding: 0.659rem 1rem;
    text-transform: uppercase;
    font-weight: 500;

    &:hover,
    &:focus,
    &:active {
      box-shadow: none;
      background: darken($secondary-color, 10%) !important;
    }
  }
}

.p-column-filter-menu-button:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none !important;
}

.p-column-filter-menu-button {

  &.p-column-filter-menu-button-open,
  &.p-column-filter-menu-button-open:hover,
  &:hover {
    background: transparent;
    color: $secondary-color !important;
  }

  &.p-column-filter-menu-button-active,
  &.p-column-filter-menu-button-active:hover {
    background: $secondary-color !important;
    color: #ffffff !important;
  }
}


.p-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none !important;
}

.p-column-filter-overlay {
  min-width: 17.5rem !important;

  .p-dropdown-item {
    white-space: normal !important;
  }
}

.button-primary {
  @include button($primary-color);
}

.button-secondary {
  @include button($secondary-color);
}

.button-tertiary {
  @include button($tertiary-color);
}

.button-danger {
  @include button($danger-color);
}

.button-warning {
  @include button($warning-color);
}

.button-text {
  @include button($text-color);
}

.delete-link {
  color: $danger-color;
  cursor: pointer;
  font-weight: 400;
  text-decoration: underline;
  transition: color .3s ease-in-out;

  &:hover {
    color: #fff;
  }
}

p-card {
  height: 100%;

  .p-card-header {
    max-height: 330px;
    position: relative;
  }

  .p-card-body {
    padding: 0 !important;

    .p-card-title {
      font-size: 1.2rem;
      background: #F6F6F9;
      text-align: center;
      padding: 15px 10px;
      font-weight: 600;
      min-height: 80px;
      margin-bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .p-card-content {
      padding: 0;
    }
  }

  .btn-wrapper {
    padding: 15px;

    p-splitButton {
      display: flex;
      justify-content: center;
      border-radius: 25px;
      box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
        0px 2px 2px 0px rgb(0 0 0 / 14%),
        0px 1px 5px 0px rgb(0 0 0 / 12%);

      .p-splitbutton-defaultbutton {
        border-radius: 25px 0 0 25px;
      }

      .p-splitbutton-menubutton {
        border-radius: 0 25px 25px 0;
      }

      .btn-menu {
        .p-menuitem-link {

          .p-menuitem-text,
          .p-menuitem-icon {
            color: $secondary-color;
            text-transform: uppercase;
            font-weight: 500;

          }

          &:not(.p-disabled):hover {
            background: var(--orange-100);

            .p-menuitem-text,
            .p-menuitem-icon {
              color: #333;
            }
          }
        }
      }
    }
  }
}

.p-datatable {

  .p-datatable-scrollable-header,
  .p-datatable-scrollable-footer {
    background: transparent !important;
  }

  .p-sortable-column {
    &:focus {
      box-shadow: inset 0 0 0 0.2rem rgba($primary-color, .3) !important;
      outline: 0 none;
    }

    &.p-highlight .p-sortable-column-icon {
      color: rgba($primary-color, .7) !important;
    }
  }
}


.hover-elements {
  button {
    .pi {
      font-size: 2rem;

      &:before {
        color: #fff;
      }
    }
  }
}

.image-dialog-wrapper {
  border: 0 !important;

  >.p-dialog-content {
    height: 100%;
    padding: 40px !important;
    background: rgba(59, 59, 68, .97) !important;
    color: #fff !important;
  }
}

.p-editor-container {
  .p-editor-toolbar {
    background: #f5f6f9 !important;

    &.ql-snow {
      border: 1px solid #c8c8c8 !important;
    }
  }
}

.p-ripple.add-character-wrapper .p-ink {
  background: rgba($primary-color, .3);
}

.remove-character-btn {
  .pi {
    font-size: 0.875rem;
  }
}

.address-wrapper {
  .p-autocomplete {
    width: calc(100% - 130px);
  }
}

.map-dialog-container {
  .p-dialog-content {
    width: 100%;
    height: 100%;
    padding: 0 !important;
  }

  .map-container {
    height: calc(100% - 110px) !important;
  }
}

.p-selectbutton {
  .p-button {

    &:focus {
      box-shadow: none;
    }

    &.p-highlight {
      background: $secondary-color !important;
      border-color: $secondary-color !important;
    }
  }
}

.p-inputswitch {
  &.p-focus {
    .p-inputswitch-slider {
      box-shadow: none !important;
    }
  }

  &.p-inputswitch-checked {
    .p-inputswitch-slider {
      background: $secondary-color !important;

      &:before {
        background: darken($secondary-color, 30%) !important;
      }
    }
  }
}

.p-dropdown {
  width: 100%;
}

.p-multiselect-panel {
  .p-multiselect-items {
    .p-multiselect-item {
      &:focus {
        box-shadow: none !important;
      }

      &.p-highlight {
        background: rgba($primary-color, .2) !important;
        color: $text-color !important;
      }
    }
  }
}

.duplicate-dialog-content {
  padding: 30px;
}

.footer {
  display: flex;
  align-items: flex-end;
  text-transform: uppercase;
  width: 100%;

  >div {
    flex: 1;
    color: $primary-color;
    font-weight: 600;
    border-bottom: 1px solid #9B99A9;
    margin: 1em 0;
  }

  button {
    width: 200px;
    height: 40px;
    margin: 0 20px;
    text-transform: uppercase;
    font-weight: 700;

    &.icon-button {
      width: 40px;
    }
  }
}
